import generateRandomId from '../../../js/utilities/random-id/u-random-id.js';
import breakpoint from '../../../js/utilities/breakpoint/u-breakpoint.js';
import extend from '../../../js/utilities/extend/u-extend.js';
import icon from '../../icon/c-icon.js';

/**
 * Toggle an element with a button.
 * @param {Element} elementToToggle The element to toggle.
 */
function toggleElement (settings) {
	settings = extend(
		true,
		{
			collapsedClass: 'is-collapsed',
			toggleButtonClass: 'c-main-navigation-toggler',
			hideLabel: 'Dölj navigation',
			showLabel: 'Visa navigation'
		},
		settings || {}
	);

	var toggleButton;
	var expanded = false;
	var collapsedClass = settings.collapsedClass;
	var elementToToggle = settings.element;
    
	if (!elementToToggle) {
		return;
	}

	generateRandomId(elementToToggle, false, 'toggle-');

	breakpoint('only screen and (max-width:922px)', {
		first_enter: function () {
			if (!toggleButton) {
				toggleButton = document.createElement('button');
				toggleButton.classList.add('c-main-navigation-toggler', 'u-flex-grow-0', 'u-flex-shrink-0');
				toggleButton.appendChild(icon({
					icon: '/project/icons/menu.svg',
					size: 'sm'
				}));
				toggleButton.setAttribute('aria-expanded', false);
				toggleButton.setAttribute('aria-control', elementToToggle.id);
                
				elementToToggle.classList.add(collapsedClass);

				toggleButton.addEventListener('click', function () {
					expanded = !expanded;

					toggleButton.setAttribute('aria-expanded', expanded);

					if (expanded) {
						elementToToggle.classList.remove(collapsedClass);
						// toggleButton.innerHTML = 'Visa navigation';
					}
					else {
						elementToToggle.classList.add(collapsedClass);
						// toggleButton.innerHTML = 'Dölj navigation';
					}
				});

				elementToToggle.parentNode.insertAdjacentElement('afterend', toggleButton);
			}
		}
	});	
}

export default toggleElement;