/**
 * @file This is the main project file where all other scripts should be initiated.
 *
 * Note that since this file will be appended to the `body` element there is no need
 * to wait for the DOMContentLoaded event before initiating your scripts. In fact, it
 * will not work since the DOMContentLoaded event will have been triggered before this
 * file is loaded. Instead use an iife (1) or, as in the example below, a function
 * that is called when a custom event is triggered.
 *
 * 1. https://en.wikipedia.org/wiki/Immediately-invoked_function_expression
 *
 * Examples
 *
 * Load a self-initiating script that does not expect a DOMContentLoaded event:
 * import './path/to/script.js'
 *
 * Load a script that you need to send parameters to or initiate at a specific time:
 * First import the script:
 * import functionName from './path/to/script.js'
 * Note that the path needs to start with './' and be relative to your code root, which
 * is 'project' by default.
 * Then initiate it:
 * functionName(param);
 *
 * See the included examples for more.
 */

// import breakpoint from './js/utilities/breakpoint/u-breakpoint.js';
// import scrollableElement from './components/scrollable-element/c-scrollable-element.js';
import './js/vendor/polyfills/polyfill-custom-event.js';
import toggleElement from './components/navigation/main-navigation/c-main-navigation.js';
import countdown from './components/countdown/c-countdown.js';

document.addEventListener('newcontent', function (event) {
	let context = event.target;
	toggleElement({
		element: context.querySelector('.c-main-navigation')
	});

	countdown();
});

// document.addEventListener('newcontent', (e) => {
// 	let context = e.target;

// 	// Make tables scrollable if needed
// 	scrollableElement(context.querySelectorAll('table:not(.not-scrollable)'));

// 	// Add a breakpoint script
// 	const message = context.createElement('div');
// 	message.id = 'message';
// 	const main = context.getElementById('main');
// 	if (main) {
// 		main.insertBefore(message, main.firstChild);
// 		breakpoint('only screen and (max-width:600px)', {
// 			first_enter: function () {
// 				console.log('first enter');
// 				message.setAttribute('style', 'border:2px solid');
// 			},
// 			enter: function () {
// 				console.log('enter');
// 				message.textContent = 'The viewport is now narrower than 601px. If it is the first time it has become narrower this text will be surrounded by a black border.';
// 			},
// 			exit: function () {
// 				console.log('exit');
// 				message.removeAttribute('style');
// 				message.textContent = 'The viewport is now wider than 600px after having been narrower.';
// 			}
// 		});
// 	}
// });

document.dispatchEvent(new CustomEvent('newcontent'));
