/* Serialize label/value-object or FormData-object to querystring */

import '../../vendor/polyfills/polyfill-form-data.js';

/**
 * Serialize label/value-object or FormData-object to querystring
 * @param  {Object} obj    label/value-object or FormData-object
 * @param  {String} prefix Prefix
 * @return {String}        Serialized querystring
 */
const serialize = (obj, prefix) => {
	let str = [];
	let p;

	// If the object is a FormData instance, then convert it into a label/value object
	if (obj instanceof FormData) {
		let formData = obj;
		obj = {};
		for (var pair of formData.entries()) {
			obj[pair[0]] = pair[1];
		}
	}

	for (p in obj) {
		if (obj.hasOwnProperty(p)) {
			let k = prefix ? prefix + '[' + p + ']' : p;
			let v = obj[p];
			str.push((v !== null && typeof v === 'object') ?
				serialize(v, k) :
				encodeURIComponent(k) + '=' + encodeURIComponent(v));
		}
	}
	return str.join('&');
};

export default serialize;