export default function countdown () {
	const countdowns = document.querySelectorAll('.c-countdown');

	countdowns.forEach(initCountdown);
}

function initCountdown (element) {
	const date = element.dataset.date;
	// const dayString = element.dataset.dayString;

	// Set the date we're counting down to
	var countDownDate = new Date(date).getTime();

	// Update the count down every 1 second
	var interval = setInterval(function () {
		// Get today's date and time
		var now = new Date().getTime();

		// Find the distance between now and the count down date
		var distance = countDownDate - now;

		// Time calculations for days, hours, minutes and seconds
		var days = Math.floor(distance / (1000 * 60 * 60 * 24));
		var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
		var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
		// var seconds = Math.floor((distance % (1000 * 60)) / 1000);

		element.innerHTML = format(days) + ':' + format(hours) + ':'
            + format(minutes);

		if (distance < 0) {
			clearInterval(interval);
			element.innerHTML = '00:00:00';
		}
	}, 1000);
}

function format(num) {
	return (num < 10 ? '0' : '') + num;
}